import integrateAccordion from './accordion/integrate';
import integrateAlert from './alert/integrate';
import integrateBreadcrumb from './breadcrumb/integrate';
// import integrateBadge from './badge/integrate';
import integrateButton from './button/integrate';
import integrateCallout from './callout/integrate';
import integrateCard from './card/integrate';
import integrateCheckbox from './checkbox/integrate';
import integrateConnect from './connect/integrate';
import integrateConsent from './consent/integrate';
// import integrateContent from './content/integrate';
import integrateDownload from './download/integrate';
import integrateFollow from './follow/integrate';
import integrateFooter from './footer/integrate';
import integrateHeader from './header/integrate';
import integrateHighlight from './highlight/integrate';
import integrateLink from './link/integrate';
import integrateInput from './input/integrate';
import integrateModal from './modal/integrate';
import integrateNavigation from './navigation/integrate';
import integrateNotice from './notice/integrate';
import integratePagination from './pagination/integrate';
import integrateQuote from './quote/integrate';
import integrateRadio from './radio/integrate';
import integrateSearch from './search/integrate';
import integrateSelect from './select/integrate';
import integrateShare from './share/integrate';
import integrateSidemenu from './sidemenu/integrate';
// import integrateStepper from './stepper/integrate';
import integrateSummary from './summary/integrate';
import integrateTab from './tab/integrate';
import integrateTable from './table/integrate';
import integrateTag from './tag/integrate';
import integrateTile from './tile/integrate';
import integrateToggle from './toggle/integrate';
// import integrateTooltip from './tooltip/integrate';
import integrateTranscription from './transcription/integrate';
import integrateTranslate from './translate/integrate';
import integrateUpload from './upload/integrate';

const integrateComponents = () => {
  integrateAccordion();
  integrateBreadcrumb();
  integrateAlert();
  // integrateBadge();
  integrateButton();
  integrateCallout();
  integrateConnect();
  integrateConsent();
  // integrateContent();
  integrateCard();
  integrateInput();
  integrateCheckbox();
  integrateDownload();
  integrateFooter();
  integrateFollow();
  integrateHeader();
  integrateHighlight();
  integrateLink();
  integrateModal();
  integrateNavigation();
  integrateNotice();
  integratePagination();
  integrateQuote();
  integrateRadio();
  integrateSearch();
  integrateSelect();
  integrateShare();
  integrateSidemenu();
  // integrateStepper();
  integrateSummary();
  integrateTab();
  integrateTable();
  integrateTag();
  integrateTile();
  integrateToggle();
  // integrateTooltip();
  integrateTranscription();
  integrateTranslate();
  integrateUpload();
};

export default integrateComponents;
